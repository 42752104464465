<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Empresas</h1>

    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <router-link :to="`/${route}/form`" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Nova empresa</span>
          </router-link>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 xl:col-span-8 mb-1 mt-1">
          <label for="busca" class="block text-sm font-medium">Buscar por nome</label>
          <input v-model="busca" type="text" name="busca" id="busca" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
      </div>
      
      <div class="col-span-12 md:col-span-4 mb-1 mt-2">
        <label class="block text-sm font-medium">Buscar por filtro </label>
        <button @click="start" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} empresas cadastradas </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                 
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.nome ? item.nome : ''}}
                      </div>
                    </div>
                  </td>

                 
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">

                    <router-link 
                    :to="`/${route}/form/${item._id}`"
                    class="text-blue-500 rounded py-1 px-4">
                    Editar
                    </router-link>

                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                      Excluir
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      busca: '',
      data: '',
      route: 'empresas',
      list: [],
      page: 1,
      perPage: 10,
      total: 0,
    }
  },
  methods: {

    async start() {
      this.page = 1;
      const listReq = await this.$http.post(`/v1/${this.route}/list`, {  busca: this.busca, data: this.data, limit: this.perPage });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async remove(id) {
      if (confirm("Tem certeza que deseja excluir essa empresa?")) {
        await this.$http.delete(`/v1/${this.route}/${id}`);
        this.$vToastify.success("Removido com sucesso!");
        this.start();
      }
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, data: this.data, limit, skip });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    }
  },
  async beforeMount() {
    this.start();
  },
}
</script>